import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Header from './Header';
import Layout from './Layout';
import Login from './LoginPage';
import PrivateRoute from './PrivateRoute';
import AdminPhrase from './Admin/AdminPagePhrase';
import AdminPlace from './Admin/AdminPagePlace';
import AdminEmail from './Admin/AdminPageEmail';

import './App.css';

function App() {
  return (
    <div className="app">
      <Header />
      <Switch>
        <Route exact path="/">
          <Layout
            pageType="home"
            asideType="phrases"
          />
        </Route>
        <Route path="/phrases">
          <Layout
            pageType="phrases"
            asideType="places"
          />
        </Route>
        <Route path="/places">
          <Layout
            pageType="places"
            asideType="phrases"
          />
        </Route>
        <Route path="/login">
          <Login />
        </Route>

        <PrivateRoute exact path="/admin">
          <AdminPhrase />
        </PrivateRoute>
        <PrivateRoute path="/admin/add-phrase">
          <AdminPhrase modifyType="add" />
        </PrivateRoute>
        <PrivateRoute path="/admin/add-place">
          <AdminPlace modifyType="add" />
        </PrivateRoute>

        <PrivateRoute path="/admin/email">
          <AdminEmail modifyType="email" />
        </PrivateRoute>
      </Switch>
    </div>
  );
}

export default App;
