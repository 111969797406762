import React from 'react';
import PropTypes from 'prop-types';

import Container from './PictureGalleryContainer';

const PictureMain = ({ pageType }) => {
  return (
    <main>
      <Container pageType={pageType} />
    </main>
  );
};

PictureMain.propTypes = {
  pageType: PropTypes.string,
};

export default PictureMain;
