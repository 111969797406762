import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AsideGallery from './AsideGallery';

const AsideGalleryContainer = ({ asideType }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    let fetchUrl = '';

    if (asideType === 'phrases') {
      fetchUrl = 'https://www.jaymesyoung.com/api/phrases?recent=t';
    } else {
      fetchUrl = 'https://www.jaymesyoung.com/api/places?recent=t';
    }

    fetch(fetchUrl)
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          setData(responseData.data);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error fetching and parsing data", error);
        setError(true);
        setLoading(false);
      });
  }, [asideType]);

  return (
    <div>
      <AsideGallery
        asideType={asideType}
        error={error}
        loading={loading}
        data={data}
      />
    </div>
  );
};

AsideGalleryContainer.propTypes = {
  asideType: PropTypes.string,
};

export default AsideGalleryContainer;
