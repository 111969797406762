import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './formFile.module.css';

const FormFile = ({ name, handleChangeFile }) => {
  const [overStyle, setOverStyle] = useState('');
  // const [previewImg, setPreviewImg] = useState({});

  // const handleFilesPreview = (files) => {
  //   console.log('handleFilesPreview', files);
  //   // handleFile(files[0]);
  //   // return;

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];

  //     if (!file.type.startsWith('image/')) { continue }

  //     console.log('file: ', file);
  //     // handleFile();

  //     const img = document.createElement('img');
  //     img.classList.add('obj');
  //     img.file = file;

  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       setPreviewImg(e.target.result);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
    setOverStyle(styles.over);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setOverStyle('');
  };

  const handleFiles = (files) => {
    // setFileObj(files[0]);

    const file = files[0];

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = document.createElement('img');
        img.src = reader.result;
        img.id = 'formImg';
        img.file = file;
        img.className = 'image';
        const dropArea = document.getElementById('drop-area');
        const imgArray = dropArea.getElementsByTagName('IMG');
        if (imgArray.length > 0) {
          dropArea.lastElementChild.remove();
          dropArea.appendChild(img);
        } else {
          dropArea.appendChild(img);
        }
      };
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const dt = e.dataTransfer;
    const { files } = dt;
    handleFiles(files);
    handleChangeFile({ file: files[0].name });
  };

  return (
    <div
      id="drop-area"
      className={`${styles.fileDiv} ${overStyle} `}
      onDragOver={(e) => handleDragOver(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDrop={(e) => handleDrop(e)}
    >
      <label
        htmlFor="fileInput"
        className={styles.fileLabel}
      >
        <input
          id="fileInput"
          className={styles.fileInput}
          name={name}
          type="file"
          accept="jpg"
          onChange={(e) => { handleFiles(e.target.files); }}
        />
        <span className={styles.text}>Drag Image here or click to upload</span>
      </label>
    </div>
  );
};

FormFile.propTypes = {
  name: PropTypes.string,
  handleChangeFile: PropTypes.func,
};

export default FormFile;
