import React, { useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import FormInput from './FormComponents/FormInput';
import FormButton from './FormComponents/FormButton';
import { JaymesContext } from './Context';

import styles from './loginPage.module.css';

const Login = () => {
  const { actions } = useContext(JaymesContext);
  const history = useHistory();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [formState, setFormState] = useReducer(reducer, { user: '', password: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('user', formState.user);
    formData.append('password', formState.password);

    fetch('https://jaymesyoung.com/api/auth/login', {
      method: 'POST',
      body: formData,
    })
      .then((reponse) => reponse.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          actions.setIsLoggedIn(true);

          // Set session storage
          sessionStorage.setItem('isLoggedIn', true);
          sessionStorage.setItem('jwt', responseData.data.jwt);

          const newPath = '/admin';
          history.push(newPath);
        } else {
          actions.setIsLoggedIn(false);
        }
      })
      .catch((error) => {
        console.log('Error fetching and parsing data', error);
      });
  };

  return (
    <div className={styles.innerWrap}>
      <h1>Login</h1>
      <form
        className={styles.loginForm}
        onSubmit={handleFormSubmit}
      >
        <FormInput
          label=""
          name="user"
          placeholder="User"
          handleChange={handleChange}
        />
        <FormInput
          label=""
          name="password"
          type="password"
          placeholder="Password"
          handleChange={handleChange}
        />
        <FormButton label="Log In" />
      </form>
    </div>
  );
};

export default Login;
