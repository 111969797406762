import React from 'react';
import PropTypes from 'prop-types';

import styles from './formButton.module.css';

const FornButton = ({ label }) => (
  <button
    className={styles.button}
    type="submit"
  >
    {label}
  </button>
);

FornButton.propTypes = {
  label: PropTypes.string,
};

export default FornButton;
