import React from 'react';
import PropTypes from 'prop-types';

import styles from './pictureGalleryImage.module.css';

const PictureGalleryImage = ({
  image, pageType, setIsImageViewShown, index, setClickedIndex,
}) => {
  let caption;

  const handleClick = () => {
    setClickedIndex(index);
    setIsImageViewShown(true);
  };

  if (pageType === 'phrases') {
    caption = `${image.category} - ${image.subCategory}`;
  } else {
    caption = image.title;
  }

  return (
    <figure className={styles.figure}>
      <button type="button" onClick={() => handleClick()}>
        <img
          src={`https://jaymesyoung.com/${image.imageUrlMd}`}
          alt={caption}
        />
      </button>
      <figcaption>{caption}</figcaption>
    </figure>
  );
};

PictureGalleryImage.propTypes = {
  image: PropTypes.objectOf(PropTypes.string),
  pageType: PropTypes.string,
  setIsImageViewShown: PropTypes.func,
  index: PropTypes.number,
  setClickedIndex: PropTypes.func,
};

export default PictureGalleryImage;
