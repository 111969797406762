import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { JaymesContext } from '../Context';

import logo from './jaymes-young-logo.svg';
import home from './home.svg';

import styles from './header.module.css';
import HeaderAdmin from './HeaderAdmin';

const Header = () => {
  const { isLoggedIn } = useContext(JaymesContext);

  const activeStyleHome = {
    borderBottom: 'dashed 1px #fff',
    borderRadius: '10px',
    padding: '5px',
  };

  const activeStyle = {
    borderBottom: 'dashed 1px #fff',
    borderRadius: '10px',
  };

  return (
    <header className={styles.header}>
      <nav className={`${styles.nav} innerWrap`}>
        <span className={styles.logo}>
          <img src={logo} alt="" />
        </span>
        <ul className={styles.ulMain}>
          <li className={styles.homeIcon}><NavLink exact to="/" activeStyle={activeStyleHome}><img src={home} alt="home icon" /></NavLink></li>
          <li><NavLink to="/phrases" activeStyle={activeStyle}>Phrases</NavLink></li>
          <li><NavLink to="/places" activeStyle={activeStyle}>Places</NavLink></li>
        </ul>
        <HeaderAdmin isLoggedIn={isLoggedIn} />
      </nav>
    </header>
  );
};

export default Header;
