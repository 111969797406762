import React from 'react';
import PropTypes from 'prop-types';

import Home from './HomePage';
import PictureMain from './PictureMain';
import Aside from './Aside';

import styles from './layout.module.css';

const Layout = ({ pageType, asideType }) => {
  let mainComponent;

  if (pageType === 'home') {
    mainComponent = <Home />;
  } else {
    mainComponent = <PictureMain pageType={pageType} />;
  }

  return (
    <div className={styles.innerWrap}>
      <div className={styles.layout}>
        {mainComponent}
        <Aside asideType={asideType} />
      </div>
    </div>
  );
};

Layout.propTypes = {
  pageType: PropTypes.string,
  asideType: PropTypes.string,
};

export default Layout;
