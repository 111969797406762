import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PictureGalleryImage from './PictureGalleryImage';
import ImageView from '../ImageVeiw';

import styles from './pictureGallery.module.css';

const PictureGallery = ({
  pageType, error, loading, data,
}) => {
  const [isImageViewShown, setIsImageViewShown] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  let header;

  const images = data.map((image, i) => (
    <PictureGalleryImage
      key={image.id}
      pageType={pageType}
      index={i}
      image={image}
      setClickedIndex={setClickedIndex}
      setIsImageViewShown={setIsImageViewShown}
    />
  ));

  if (pageType === 'phrases') {
    header = 'German Phrases to Help You Get Around';
  } else {
    header = 'Places I Want to Visit';
  }

  return (
    <div>
      <h1>{header}</h1>

      <ul className={styles.galleryGrid}>
        {images}
      </ul>
      {isImageViewShown && (
        <ImageView
          currentData={data}
          currentItem={clickedIndex}
          setIsImageViewShown={setIsImageViewShown}
        />
      )}
    </div>
  );
};

PictureGallery.propTypes = {
  pageType: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default PictureGallery;
