import React, {
  useState, useEffect, useCallback, useReducer,
} from 'react';
import PropTypes from 'prop-types';

// import FormInput from '../FormComponents/FormInput';
// import FormSelect from '../FormComponents/FormSelect';
import FormButton from '../FormComponents/FormButton';
import FormMessage from '../FormComponents/FormMessage';
import withFormWrap from './withFormWrap';

import styles from './adminPage.module.css';

const AdminPageEmail = ({
  handleSubmit,
  modifyType,
  fetchUpdatedData,
  updateData,
  actionSuccess,
}) => {
  const initialFormState = {
    file: '',
    category: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const [messageValues, setMessageValues] = useState({ message: '', status: '' });
  const [selectedFiles, setSelectedFiles] = useState();

  useEffect(() => {
    if (updateData.id !== undefined) {
      setFormState(updateData);
    }
  }, [updateData]);

  const clearForm = () => {
    if (modifyType === 'update') {
      setFormState({
        file: '',
        category: '',
      });
    } else {
      setFormState({
        file: '',
      });
    }
  };

  const memoizedClearForm = useCallback(clearForm, []);

  useEffect(() => {
    if (actionSuccess === true) {
      memoizedClearForm();
    }
  }, [actionSuccess, memoizedClearForm]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormState({ [name]: value });
  // };

  const handleFile = (e) => {
    // console.log(e.target.files);
    // console.log(e.target.files[0]);
    // console.log(e.target.files[0].size);
    // console.log(e.target.files[0].name);
    // setSelectedFiles(e.target.files[0]);
    setFormState({ file: e.target.files[0] });

    // setSelectedFiles(e.target.files[0]);
    // const formFiles = e.target.files[0];
    // console.log(formFiles);
    // setSelectedFiles(formFiles);
    // console.log(selectedFiles);
    // setFormState({ file: e.target.files });

    const fileToUpload = document.getElementById('file');

    // console.log(fileToUpload);
  };

  const isValid = () => {
    // if (formState.file === ''
    // ) {
    //   return false;
    // }
    return true;
  };

  const handleFocus = () => {
    setMessageValues({ message: '', status: '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });

    fetchUrl = 'https://jaymesyoung.com/api/email';

    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };

  // const categoryOptions = [
  //   { id: '1', value: 'castle', name: 'Castle' },
  // ];

  return (
    <div className={styles.wrap}>
      <h1>Send Email</h1>
      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        onFocus={handleFocus}
      >
        <div>
          <label className={styles.label} htmlFor="file">
            File
            <input
              id="file"
              name="file"
              // placeholder="Browse"
              type="file"
              accept=".csv"
              // value={formState.file}
              onChange={handleFile}
            // onChange={(e) => setSelectedFiles(e.target.files[0])}
            />
          </label>

          <FormButton label="Send Email" />
        </div>

      </form>
      <FormMessage messageValues={messageValues} />
    </div>
  );
};

AdminPageEmail.propTypes = {
  handleSubmit: PropTypes.func,
  modifyType: PropTypes.string,
  updateData: PropTypes.objectOf(PropTypes.string),
  fetchUpdatedData: PropTypes.func,
  actionSuccess: PropTypes.bool,
};

export default withFormWrap(AdminPageEmail);
