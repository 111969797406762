import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const JaymesContext = React.createContext();
JaymesContext.displayName = 'JaymesContext';
const { Consumer } = JaymesContext;
export const JaymesConsumer = Consumer;

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'de',
      loading: true,
      error: false,
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    const loggedIn = sessionStorage.getItem('isLoggedIn');

    if (loggedIn) {
      this.setState({ isLoggedIn: true });
    }

    // if (categories) {
    //   this.setState({
    //     jsonCategories: JSON.parse(categories),
    //     loading: false,
    //   });
    // } else {
    //   fetch('https://phoenixjaymes.com/api/language/categories/app')
    //     .then((reponse) => reponse.json())
    //     .then((responseData) => {
    //       sessionStorage.setItem(
    //         'categories',
    //         JSON.stringify(responseData.data),
    //       );
    //       this.setState({
    //         jsonCategories: responseData.data,
    //         loading: false,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log('Error fetching and parsing data', error);

    //       this.setState({
    //         loading: false,
    //         error: true,
    //       });
    //     });
    // }
  }

  setIsLoggedIn = (loggedIn) => {
    if (loggedIn === true) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
      sessionStorage.removeItem('isLoggedIn');
    }
  };

  setLanguage = (lang) => {
    this.setState({ lang });
  };

  setDocumentTitle = (title) => {
    document.title = `${title} | Jaymes Young`;
  };

  render() {
    const {
      isLoggedIn, lang, loading, error,
    } = this.state;
    const { children } = this.props;
    const value = {
      lang,
      isLoggedIn,
      loading,
      error,
      actions: {
        setLanguage: this.setLanguage,
        setIsLoggedIn: this.setIsLoggedIn,
        setDocumentTitle: this.setDocumentTitle,
      },
    };

    return (
      <JaymesContext.Provider value={value}>
        {children}
      </JaymesContext.Provider>
    );
  }
}

Provider.propTypes = {
  children: PropTypes.element,
};

/**
 * A higher-order component that wraps the provided component in a Context Consumer component.
 * @param {class} WrappedComponent - A React component.
 * @returns {function} A higher-order component.
 */

export default function withContext(WrappedComponent) {
  return function ContextComponent(props) {
    return (
      <Consumer>
        {(context) => <WrappedComponent {...props} context={context} />}
      </Consumer>
    );
  };
}
