const homeData = [
  {
    id: '1',
    type: 'p',
    text: 'I\'m Jaymes and I live in Portland, Oregon. I consider myself pretty happy, but I\'m tired and I\'m frustrated. I\'m tired of the poor  treatment of people of color by the police, I\'m tired of mass school shootings and I\'m also tired of the protests, the rioting, and the looting. What I\'m most tired of is politicians promising change and never delivering it. All of this is exhausting and very frustrating.',
  },
  {
    id: '2',
    type: 'p',
    text: 'Since I was young I\'ve always wanted to learn German and see Germany, because of this I\'ve taken two years of German and have been studying it on Duolingo. I would like to continue my studies, and the best way to do this is an immersion program in Germany. An immersion program would bring my skill level up to a point where I can work and live in Germany or even Austria or Switzerland, should I choose to leave the country. I\'ve recently been diagnosed with Parkinson\'s disease, and with the way it progresses, I should probably do this now, rather than later. Just a small donation of $5 or $10 would help me get to my goal.',
  },
  {
    id: '3',
    type: 'h',
    text: 'Why Germany',
  },
  {
    id: '4',
    type: 'p',
    text: 'I  really like the German language, the sound, the grammar, and the complexity. I enjoy learning German and am also interested in other Germanic languages like Dutch. I also love Grimm\'s fairy tales and have read most of them and also often listen to them in German. Then there\'s German Fairy Tale Route, there are attractions along the route that are focused around the brothers Grimm, also along the route are regions which are linked to the fairy tales found in the Grimm collection, such as The Town Musicians of Bremen. ',
  },
  {
    id: '5',
    type: 'p',
    text: 'I also love castles, they\'re just cool and Germany has plenty of castles to see. This is a short list:',
  },
  {
    id: '6',
    type: 'ul',
    text: [
      { id: '1', text: 'Neuschwanstein Castle' },
      { id: '2', text: 'Lichtenstein Castle' },
      { id: '3', text: 'Heidelberg Castle' },
      { id: '4', text: 'Burg Eltz' },
      { id: '5', text: 'Hohenschwangau Castle' },
      { id: '6', text: 'Drachenburg Castle' },
      { id: '7', text: 'Mespelbrunn Castle' },
      { id: '8', text: 'Schwerin Castle' },
      { id: '9', text: 'Cochem Castle' },
      { id: '10', text: 'Hohenzollern Castle' },
    ],
  },
  {
    id: '7',
    type: 'h',
    text: 'Why I\'m Tired and Frustrated',
  },
  {
    id: '8',
    type: 'p',
    text: 'I\'m tired and frustrated over the racism in America and how the police treat people of color. I shouldn\'t be afraid to interact with the people who are supposed to keep me safe and neither should any other person, especially a person of color. I lived in Long Beach, California when Rodney King was beaten, which means I was there during the riots. The police practically beat Rodney King to death. They were charged, tried, and ultimately acquitted of all charges, which led to riots and protest. It\'s been 28 years since that happened and there has been very little police reform since it happened. Below is a short list of people injured or killed in officer-involved incidents:',
  },
  {
    id: '9',
    type: 'ul',
    text: [
      { id: '1', text: 'Jacob Blake' },
      { id: '2', text: 'George Floyd' },
      { id: '3', text: 'Breonna Taylor' },
      { id: '4', text: 'Trayvon Martin' },
      { id: '5', text: 'Tamir Rice' },
      { id: '6', text: 'Michael Brown' },
      { id: '7', text: 'Eric Garner' },
      { id: '8', text: 'Philando Castile' },
      { id: '9', text: 'Justin Howell' },
      { id: '10', text: 'Sean Monterrosa' },
      { id: '11', text: 'Jamel Floyd' },
    ],
  },
  {
    id: '10',
    type: 'p',
    text: 'Like I said this is a short list. There are many more people, but sadly that would take up too much space. As mentioned before 28 years after Rodney King these types of things are still happening, I don\'t believe Jacob Blake will be the last. All of this violence and division is exhausting, I need a fresh start away from all of the violence.',
  },
  {
    id: '11',
    type: 'p',
    text: 'I\'m also tired of the school shootings and I\'m frustrated that very little gets done after they happen. The politicians show outrage and make promises, then a few weeks later things back to the way they were before, as if nothing happened. As of November 19, 2019 there had been 45 school shootings for the year, according to CNN.com. These shootings happened at elementary, middle and high schools and also on college and university campuses. Below is a list of the top ten school shootings by death toll:',
  },
  {
    id: '12',
    type: 'ol',
    text: [
      { id: '1', text: 'Virginia Tech Massacre, 33 dead 17 injuries' },
      { id: '2', text: 'Sandy Hook Elementary Shooting, 28 dead 2 injuries' },
      { id: '3', text: 'University of Texas tower shooting, 18 dead 31 injuries' },
      { id: '4', text: 'Stoneman Douglas High School shooting, 17 dead 17 injuries' },
      { id: '5', text: 'Columbine High School Massacre, 15 dead 21 injuries' },
      { id: '6', text: 'Santa Fe High School shooting, 10 dead 14 injuries' },
      { id: '7', text: 'Umpqua Community College Shooting, 10 dead 9 injuries' },
      { id: '8', text: 'Red Lake Shootings, 10 dead 7 injuries' },
      { id: '9', text: 'Enoch Brown School Massacre, 10 dead 2 injuries' },
      { id: '10', text: 'Oikos University shooting, 7 dead 3 injuries' },
    ],
  },
  {
    id: '13',
    type: 'p',
    text: 'After these shootings there were protest, outrage, and political promises but very little else. After each shooting one would expect to see change, but it didn\'t happen.',
  },
  {
    id: '14',
    type: 'h',
    text: 'How the funds will be used',
  },
  {
    id: '15',
    type: 'p',
    text: 'I Intend to enroll in a German language program for a year and I must have all costs secured before I can enter the country for study. I will not be able to work in Germany so the majority of the funds will be used for:',
  },
  {
    id: '16',
    type: 'ul',
    text: [
      { id: '1', text: 'Food and housing' },
      { id: '2', text: 'School fees and supplies' },
      { id: '3', text: 'Health insurance' },
      { id: '4', text: 'Misc leisure activities, castles' },
    ],
  },
  {
    id: '17',
    type: 'p',
    text: 'Just $5 or $10 would help, but any contribution made is greatly appreciated.',
  }
];

export default homeData;
