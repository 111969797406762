import React from 'react';
// import { Link } from 'react-router-dom';

import DonateButton from './Donate/DonateButton';

import imgCastle from './homePageImg-01.jpg';
import imgFace from './homePageImgFace-01.jpg';
import homeData from '../data/homeData';
import styles from './HomePage.module.css';

const HomePage = () => {
  const content = homeData.map((item) => {
    if (item.type === 'h') {
      return <h3 key={item.id}>{item.text}</h3>;
    }

    if (item.type === 'ul') {
      const listItems = item.text.map((listItem) => <li key={listItem.id}>{listItem.text}</li>);
      return <ul key={item.id}>{listItems}</ul>;
    }

    if (item.type === 'ol') {
      const listItems = item.text.map((listItem) => <li key={listItem.id}>{listItem.text}</li>);
      return <ol key={item.id}>{listItems}</ol>;
    }

    return <p key={item.id}>{item.text}</p>;
  });

  return (
    <main className={styles.home}>
      <div className={styles.hero}>
        {/* <h1 className={styles.heroH1}>Home Jumbo</h1>
        <Link to="https://gofundme.com/f/help-jaymes-realize-his-german-fairytale-dream">Donate</Link> */}
        <img className={styles.imgCastle} src={imgCastle} alt="Neuschwanstein Castle" />
        <img className={styles.imgFace} src={imgFace} alt="Jaymes" />
      </div>
      <h1>Help Jaymes Realize His German Fairytale Dream</h1>

      <p className={styles.donateWrapTop}>
        <DonateButton />
      </p>

      {content}

      <p className={styles.donateWrapBottom}>
        <DonateButton />
      </p>
    </main>
  );
};

export default HomePage;
