import React from 'react';
import PropTypes from 'prop-types';

import styles from './formSelect.module.css';

const FormSelect = ({
  name, label, options, selected, handleChange,
}) => {
  const selectOptions = options.map((category) => (
    <option key={category.id} value={category.value}>{category.name}</option>
  ));

  return (
    <label htmlFor={name} className={styles.label}>
      {label}
      <select
        id={name}
        name={name}
        value={selected}
        onChange={handleChange}
      >
        <option value="">{`Select ${label}`}</option>
        {selectOptions}
      </select>
    </label>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
  handleChange: PropTypes.func,
};

export default FormSelect;
