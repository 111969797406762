import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { JaymesContext } from './Context';

const PrivateRoute = ({ children, exact, path }) => {
  const { isLoggedIn } = useContext(JaymesContext);

  if (exact) {
    return (
      <Route
        exact
        path={path}
        render={() => (isLoggedIn ? children : <Redirect to="/login" />)}
      />
    );
  }

  return (
    <Route
      path={path}
      render={() => (isLoggedIn ? children : <Redirect to="/login" />)}
    />
  );
};

PrivateRoute.defaultProps = {
  exact: false,
};

PrivateRoute.propTypes = {
  children: PropTypes.element,
  path: PropTypes.string,
  exact: PropTypes.bool,
};

export default PrivateRoute;
