import React from 'react';
import PropTypes from 'prop-types';

import Container from './AsideGalleryContainer';
import Donate from '../Donate';
import Footer from '../Footer';

// import styles from './aside.module.css';

const Aside = ({ asideType }) => {
  return (
    <aside>
      <Container asideType={asideType} />
      <Donate />
      <Footer />
    </aside>
  );
};

Aside.propTypes = {
  asideType: PropTypes.string,
};

export default Aside;
