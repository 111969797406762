import React from 'react';
import PropTypes from 'prop-types';

import AsideGalleryImage from './AsideGalleryImage';

import styles from './asideGallery.module.css';

const AsideGallery = ({
  asideType, error, loading, data,
}) => {
  const images = data.map((image) => (
    <AsideGalleryImage key={image.id} image={image} />
  ));

  return (
    <div>
      <h2>Recent {asideType}</h2>

      <ul className={styles.galleryGrid}>
        {images}
      </ul>
    </div>
  );
};

AsideGallery.propTypes = {
  asideType: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default AsideGallery;
