import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import close from './close.svg';
import previous from './cheveron-left.svg';
import next from './cheveron-right.svg';

import styles from './index.module.css';

const ImageView = ({ currentData, currentItem, setIsImageViewShown }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentImage, setCurrentImage] = useState({ url: '', title: '' });

  useEffect(() => {
    setCurrentIndex(currentItem);
    setCurrentImage({
      url: currentData[currentItem].imageUrl,
      title: currentData[currentItem].title,
    });
  }, [currentData, currentItem]);

  const handlePrevClick = () => {
    if (currentIndex <= 0) {
      return;
    }

    setCurrentImage({
      url: currentData[currentIndex - 1].imageUrl,
      title: currentData[currentIndex - 1].title,
    });
    setCurrentIndex(currentIndex - 1);
  };

  const handleNextClick = () => {
    const lastIndex = currentData.length - 1;
    if (currentIndex >= lastIndex) {
      return;
    }

    setCurrentImage({
      url: currentData[currentIndex + 1].imageUrl,
      title: currentData[currentIndex + 1].title,
    });
    setCurrentIndex(currentIndex + 1);
  };

  const handleImageViewClick = (e) => {
    if (!e.target.closest('#jsImg')) {
      setIsImageViewShown(false);
    }
  };

  return (
    <div className={styles.wrap} onClick={(e) => handleImageViewClick(e)} role="presentation">
      <div className={styles.closeDiv}>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={() => setIsImageViewShown(false)}
        >
          <img src={close} alt="close" />
        </button>
      </div>

      <div id="jsImg" className={styles.imgWrap}>
        <img src={`https://jaymesyoung.com/${currentImage.url}`} alt={currentImage.title} />

        <div className={styles.btnWrapLeft}>
          {currentIndex !== 0 && (
            <button
              type="button"
              className={styles.prevBtn}
              onClick={() => handlePrevClick()}
            >
              <img src={previous} alt="left cheveron" />
            </button>
          )}
        </div>

        <div className={styles.btnWrapRight}>
          {currentIndex !== currentData.length - 1 && (
            <button
              type="button"
              className={styles.nextBtn}
              onClick={() => handleNextClick()}
            >
              <img src={next} alt="right cheveron" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ImageView.propTypes = {
  currentData: PropTypes.arrayOf(PropTypes.object),
  currentItem: PropTypes.number,
  setIsImageViewShown: PropTypes.func,
};

export default ImageView;
