import React, {
  useState, useEffect, useCallback, useReducer,
} from 'react';
import PropTypes from 'prop-types';

import FormInput from '../FormComponents/FormInput';
import FormSelect from '../FormComponents/FormSelect';
import FormFile from '../FormComponents/FormFile';
import FormButton from '../FormComponents/FormButton';
import FormMessage from '../FormComponents/FormMessage';
import withFormWrap from './withFormWrap';

import styles from './adminPage.module.css';

const AdminPagePlace = ({
  handleSubmit,
  modifyType,
  fetchUpdatedData,
  updateData,
  actionSuccess,
}) => {
  const initialFormState = {
    file: '',
    title: '',
    description: '',
    category: '',
  };
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [formState, setFormState] = useReducer(reducer, initialFormState);
  const [messageValues, setMessageValues] = useState({ message: '', status: '' });

  useEffect(() => {
    if (updateData.id !== undefined) {
      setFormState(updateData);
    }
  }, [updateData]);

  const clearForm = () => {
    if (modifyType === 'update') {
      setFormState({
        file: '',
        title: '',
        description: '',
        category: '',
      });
    } else {
      setFormState({
        file: '',
        title: '',
        description: '',
      });
    }
  };

  const memoizedClearForm = useCallback(clearForm, []);

  useEffect(() => {
    if (actionSuccess === true) {
      memoizedClearForm();
    }
  }, [actionSuccess, memoizedClearForm]);

  // const handleIconClick = (e) => {
  //   const itemId = e.target.getAttribute('data-id');
  //   fetchUpdatedData(`https://phoenixjaymes.com/api/language/adjectives/${itemId}`);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ [name]: value });
  };

  // const handleChangeFile = (file) => {
  //   console.log(file);
  //   // setFormState({ testFile: file.dataTransfer.files });
  // };

  const isValid = () => {
    if (formState.title === ''
      || formState.description === ''
      || formState.category === ''
    ) {
      return false;
    }
    return true;
  };

  const handleFocus = () => {
    setMessageValues({ message: '', status: '' });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let fetchUrl = '';

    if (!isValid()) {
      setMessageValues({
        message: 'Please fill in all feilds',
        status: 'fail',
      });
      return;
    }

    setMessageValues({ message: '', status: '' });

    if (modifyType === 'add') {
      fetchUrl = 'https://jaymesyoung.com/api/places';
    } else {
      fetchUrl = `https://jaymesyoung.com/api/places/${formState.id}`;
    }

    handleSubmit(modifyType, fetchUrl, formState, initialFormState);
  };

  const heading = modifyType === 'update'
    ? 'Update Place'
    : 'Add Place';

  const categoryOptions = [
    { id: '1', value: 'castle', name: 'Castle' },
  ];

  return (
    <div className={styles.wrap}>
      <h1>{heading}</h1>
      <form
        className={styles.form}
        onSubmit={handleFormSubmit}
        onFocus={handleFocus}
      >
        <div>
          <FormFile
            name="file"
            handleChangeFile={setFormState}
          />
        </div>
        <div>
          <FormInput
            label="Title"
            name="title"
            value={formState.title}
            placeholder="Title"
            handleChange={handleChange}
          />
          <FormInput
            label="Description"
            name="description"
            value={formState.description}
            placeholder="Description"
            handleChange={handleChange}
          />

          <FormSelect
            label="Category"
            name="category"
            options={categoryOptions}
            selected={formState.category}
            handleChange={handleChange}
          />

          <FormButton label={heading} />
        </div>

      </form>
      <FormMessage messageValues={messageValues} />
    </div>
  );
};

AdminPagePlace.propTypes = {
  handleSubmit: PropTypes.func,
  modifyType: PropTypes.string,
  updateData: PropTypes.objectOf(PropTypes.string),
  fetchUpdatedData: PropTypes.func,
  actionSuccess: PropTypes.bool,
};

export default withFormWrap(AdminPagePlace);
