import React, { useState, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { JaymesContext } from '../Context';

import styles from './headerAdmin.module.css';

import face from './happy-boy.svg';
import gear from './gear.svg';

const HeaderAdmin = () => {
  const { isLoggedIn, actions } = useContext(JaymesContext);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const docRoot = document.getElementById('root');

  let icon;
  let showClass = '';
  const activeStyle = {
    borderBottom: 'dashed 1px #fff',
    borderRadius: '10px',
  };

  const handleDocumentClick = (e) => {
    if (!e.target.closest('#jsUlAdmin')) {
      setIsMenuShown(false);
    }
  };

  docRoot.addEventListener('click', handleDocumentClick);

  const handleIconClick = () => { setIsMenuShown(!isMenuShown); };

  const handleMenuClick = () => { setIsMenuShown(false); };

  if (isMenuShown) {
    showClass = styles.show;
  } else {
    showClass = '';
  }

  if (isLoggedIn) {
    icon = (
      <span className={styles.icon}>
        <button type="button" onClick={() => handleIconClick()}>
          <img src={face} alt="Boys Face" />
        </button>
      </span>
    );
  } else {
    icon = <span className={styles.icon}><Link to="/login"><img src={gear} alt="Gear" /></Link></span>;
  }

  return (
    <ul id="jsUlAdmin" className={styles.ulAdmin}>
      <li className={`${styles.subMenuLabel} ${showClass}`}>
        {icon}
        {isLoggedIn && (
          <ul className={styles.subMenu} onClick={() => handleMenuClick()} role="presentation">
            <li><NavLink to="/admin/add-phrase" activeStyle={activeStyle}>Add Phrase</NavLink></li>
            <li><NavLink to="/admin/add-phrase" activeStyle={activeStyle}>Update Phrase</NavLink></li>
            <li><NavLink to="/admin/add-phrase" activeStyle={activeStyle}>Delete Phrase</NavLink></li>
            <li><NavLink to="/admin/add-place" activeStyle={activeStyle}>Add Place</NavLink></li>
            <li><NavLink to="/admin/add-place" activeStyle={activeStyle}>Update Place</NavLink></li>
            <li><NavLink to="/admin/add-place" activeStyle={activeStyle}>Delete Place</NavLink></li>

            <li><NavLink to="/admin/email" activeStyle={activeStyle}>Email</NavLink></li>

            <li><button type="button" onClick={() => { actions.setIsLoggedIn(false); }}>Logout</button></li>
          </ul>
        )}
      </li>
    </ul>
  );
};

export default HeaderAdmin;
