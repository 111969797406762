import React from 'react';
import PropTypes from 'prop-types';

import styles from './formInput.module.css';

const FormInput = ({
  label, name, placeholder, type, value, handleChange,
}) => {
  return (
    <label className={styles.label} htmlFor={name}>
      {label}
      <input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleChange}
      />
    </label>
  );
};

FormInput.defaultProps = {
  type: 'text',
};

FormInput.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default FormInput;
