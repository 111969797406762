import React from 'react';
import PropTypes from 'prop-types';

import styles from './asideGalleryImage.module.css';

// import img from './german-phrases-p18a.jpg';
// import img1 from './neuschwanstein-castle.jpg';

const AsideGalleryImage = ({ image }) => {
  return (
    <figure className={styles.figure}>
      <img
        src={`https://jaymesyoung.com/${image.imageUrlSm}`}
        alt={`${image.category} - ${image.title}`}
      />
    </figure>
  );
};

AsideGalleryImage.propTypes = {
  image: PropTypes.objectOf(PropTypes.string),
};

export default AsideGalleryImage;
